// import router from "@/router";
import { apiTimeout, baseURL } from "@/common.js";
import commonFunc from "@/commonFunc";
import SocketService from "@/utils/websocket";
import axios from "axios";
import { Message } from "element-ui";

let isJumping = false; // 标记是否正在跳转页面
let isLoggingOut = false; //标记是否已经执行了登出

const instance = axios.create({
  baseURL: baseURL,
  timeout: apiTimeout,
});

instance.interceptors.request.use(
  (config) => {
    // console.log("请求拦截器---");
    // //判断是否有token，如果有，则请求头中携带上token
    let token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = token;
    }
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);
// 添加响应拦截器
instance.interceptors.response.use(
  (res) => {
    try {
      let res_data = res.data;
      if (res_data.code !== 200) {
        // token过期
        if (res_data.code === 101) {
          if (!isLoggingOut) {
            Message.error(res_data.msg);
            // 关闭WebSocket连接
            if (SocketService.Instance.connected) {
              console.log("关闭WebSocket连接");
              SocketService.Instance.ws.close();
            }
          }

          // Message.error(res_data.msg);
          localStorage.removeItem("token");
          localStorage.removeItem("total_shots_count");
          localStorage.removeItem("name");
          localStorage.removeItem("avatar");
          if (!isJumping) {
            window.location.href = "#/login";
          }
          isJumping = true;
          isLoggingOut = true;
        } else if (res_data.code === 102) {
          console.log("执行了这2");
          Message.error(res_data.msg);
        } else if (res_data.code === 100) {
          console.log("执行了这3");
          Message.error(res_data.msg);
        } else if (res_data.code === 500) {
          Message.error(res_data.msg);
        } else {
          console.log("执行了这4");
          Message.error(res_data.msg);
        }
        commonFunc.show_log_err("response-err", res_data);
        // return false;
      }
      return res_data;
    } catch (e) {
      return false;
    }
  },
  (err) => {
    try {
      if (err.response && err.response.data && err.response.data.error) {
        Message.error(err.response.data.error);
      } else {
        Message.error("网络请求错误");
      }
    } catch (e) {
      console.log("请求处理异常，出现未知错误：", e);
    }
    return false;
    // return Promise.reject(err);
  }
);

export default instance;